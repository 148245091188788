var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "results" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("h2", { staticClass: "step-label" }, [_vm._v(_vm._s(_vm.label))]),
    _vm._v(" "),
    _c("p", [_vm._v("Summary of input data and calculations.")]),
    _vm._v(" "),
    _c("div", { staticClass: "call-to-section" }, [
      _c("div", { staticClass: "row" }, [
        !_vm.dischargeManual
          ? _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2" }, [
              _vm._v("Drainage Area")
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.dischargeManual
          ? _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2 result" }, [
              _vm._v(
                _vm._s(_vm.area) +
                  " " +
                  _vm._s(_vm.areaUnit === "m" ? "m²" : "ft²")
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2" }, [
          _vm._v("Length of Drain to Outlet")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2 result" }, [
          _vm._v(_vm._s(_vm.lengthOfDrain()))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2" }, [
          _vm._v("Internal Channel Width")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2 result" }, [
          _vm._v(_vm._s(_vm.channelWidthToText(_vm.channelWidth)))
        ]),
        _vm._v(" "),
        !_vm.dischargeManual
          ? _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2" }, [
              _vm._v("Rainfall Intensity")
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.dischargeManual
          ? _c(
              "div",
              {
                class:
                  "col-xs-12 result " +
                  (_vm.dischargeManual
                    ? "col-sm-5 col-md-6"
                    : "col-sm-5 col-md-2")
              },
              [
                _vm._v(
                  _vm._s(_vm.rainfall) +
                    " " +
                    _vm._s(_vm.rainfallUnit === "m" ? "mm/hr" : " inch/hr")
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2" }, [
          _vm._v("Longitudinal Slope")
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class:
              "col-xs-12 result " +
              (_vm.dischargeManual ? "col-sm-7 col-md-2" : "col-sm-5 col-md-6")
          },
          [_vm._v(_vm._s(_vm.slope) + "%")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2" }, [
          _vm._v("Design Discharge")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2 result" }, [
          _vm._v(
            _vm._s(_vm.discharge) + " " + _vm._s(_vm.dischargeUnitDisplay())
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2" }, [
          _vm._v("Lateral Intake")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2 result" }, [
          _vm._v(_vm._s(_vm.flowRate) + " " + _vm._s(_vm.flowUnitHuman()))
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.registered
      ? _c(
          "div",
          { staticClass: "result-table page-break" },
          [
            _vm.loading
              ? _c("div", { staticClass: "loading" }, [
                  _c("span", [_vm._v("Loading results...")]),
                  _vm._v(" "),
                  _c("div")
                ])
              : [
                  _vm.results !== null && _vm.results.length > 0
                    ? [
                        _c("h2", { staticClass: "step-heading" }, [
                          _vm._v("Results")
                        ]),
                        _vm._v(" "),
                        _vm.allResultsOverCapacity() && _vm.channelWidth < 300
                          ? _c("div", { staticClass: "no-results" }, [
                              _c("p", [
                                _vm._v(
                                  "It seems that your catchment hydraulics have exceeded the capacity of the selected channel width. Try "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("setStep", 3)
                                      }
                                    }
                                  },
                                  [_vm._v("increasing the channel width")]
                                ),
                                _vm._v(".")
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.allResultsOverCapacity() && _vm.channelWidth >= 300
                          ? _c("div", { staticClass: "no-results" }, [
                              _c("p", [
                                _vm._v(
                                  "A " +
                                    _vm._s(_vm.channelWidth) +
                                    "mm solution does not appear to be suitable for the length of drain selected. Consider shortening the length of drain (increase number of outlets) and reduce the corresponding drainage (catchment) area. "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("setStep", 1)
                                      }
                                    }
                                  },
                                  [_vm._v("Start again")]
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.results, function(result, index) {
                          return _c(
                            "div",
                            { key: result.ID, staticClass: "results-row" },
                            [
                              _c("results-row", {
                                attrs: {
                                  invertDepthRange: _vm.invertDepthRange(
                                    result,
                                    _vm.lengthUnit
                                  ),
                                  localisedName: _vm.localisedName(result),
                                  run: result,
                                  countryGroup: _vm.projectCountryGroup()
                                }
                              })
                            ],
                            1
                          )
                        })
                      ]
                    : _c("div", { staticClass: "no-results" }, [
                        _c("p", [
                          _vm._v(
                            "We could not find a solution that meets your hydraulic requirements. Try varying your inputs."
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            on: {
                              click: function($event) {
                                return _vm.$emit("setStep", 1)
                              }
                            }
                          },
                          [_vm._v("Modify Values")]
                        )
                      ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "results-row results-notes" }, [
                    _c("h3", { staticStyle: { "margin-bottom": "0" } }, [
                      _vm._v("A note on specification")
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Based on your inputs, this is a hydraulic recommendation to guide you on the optimal channel \n\t\t\t\t\tsize and configuration. Select (tick) your preferred option above and hit the " +
                          _vm._s(_vm.$t("message.Enquire")) +
                          " button \n\t\t\t\t\tbelow to request a full trench drain recommendation taking also into account loading, site \n\t\t\t\t\tand user requirements. For more info on this calculation, "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "/HYDROlite_standard_notes.pdf",
                            target: "_blank"
                          }
                        },
                        [_vm._v("click here")]
                      ),
                      _vm._v(".")
                    ]),
                    _vm._v(" "),
                    _vm.lengthMetric() >= 3 && _vm.lengthMetric() <= 20
                      ? _c("div", [
                          _c("h3", { staticStyle: { "margin-bottom": "0" } }, [
                            _vm._v(
                              "A note on equivalent " +
                                _vm._s(_vm.$t("message.utilisation")) +
                                " values"
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "Length of drain of " +
                                _vm._s(_vm.lengthLocalised(3)) +
                                " to " +
                                _vm._s(_vm.lengthLocalised(20)) +
                                " will produce equivalent " +
                                _vm._s(_vm.$t("message.utilisation")) +
                                " values \n\t\t\t\t\tfor the shallow and standard configurations. This is not an error! For these length of drains, \n\t\t\t\t\tthe design of the run is identical, refer to the 'Typical run (PDF)' link for the shallow \n\t\t\t\t\tconfiguration and standard configuration."
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lengthMetric() > 39
                      ? _c("div", [
                          _c("h3", { staticStyle: { "margin-bottom": "0" } }, [
                            _vm._v(
                              "A note on equivalent " +
                                _vm._s(_vm.$t("message.utilisation")) +
                                " values"
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "A " +
                                _vm._s(_vm.lengthLocalised(40)) +
                                " length of drain will produce equivalent " +
                                _vm._s(_vm.$t("message.utilisation")) +
                                " values for the \n\t\t\t\t\tstandard and deep configurations. This is not an error! For this length of drain, \n\t\t\t\t\tthe design of the run is identical, refer to the 'Typical run (PDF)' link for the standard\n\t\t\t\t\tconfiguration and deep configuration."
                            )
                          ])
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "cta-row no-print" }, [
                    _c("div", { staticClass: "cta" }, [
                      _c("h4", [_vm._v("I want to try another width")]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "Need more capacity or want a more cost effective solution?"
                        )
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        attrs: { src: "/images/sizes.gif", alt: "" }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "cta-links" }, [
                        _c("ul", { staticClass: "cta-list" }, [
                          _c("li", [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-red btn-cta",
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("setStep", 3)
                                  }
                                }
                              },
                              [_vm._v("Try another width")]
                            )
                          ])
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cta" }, [
                      _c("h4", [_vm._v("I want a record")]),
                      _vm._v(" "),
                      _vm._m(2),
                      _vm._v(" "),
                      _c("img", {
                        attrs: { src: "/images/happy.jpg", alt: "" }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "cta-links" }, [
                        _c("ul", { staticClass: "cta-list" }, [
                          _c("li", [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-red btn-cta",
                                on: {
                                  click: function($event) {
                                    return _vm.sendResults()
                                  }
                                }
                              },
                              [_vm._v("Email me my result")]
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-red btn-cta",
                                on: {
                                  click: function($event) {
                                    return _vm.printResults()
                                  }
                                }
                              },
                              [_vm._v("Print this page")]
                            )
                          ])
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cta" }, [
                      _c("h4", [_vm._v("What about the grate?")]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "Choose a grate that works for my catchment hydraulics."
                        )
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        attrs: { src: "/images/gic-bg1.jpg", alt: "" }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "cta-links" }, [
                        _c("ul", { staticClass: "cta-list" }, [
                          _c("li", [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-red btn-cta",
                                attrs: {
                                  target: "_blank",
                                  href: _vm.urlToPrefillGic()
                                }
                              },
                              [_vm._v("Launch program")]
                            )
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _vm.enquiryComplete
                    ? _c("div", { staticClass: "thanks-row" }, [
                        _vm._v(
                          "\n\t\t\t\tThank you for your interest! If you wish to make further enquiries "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.reset()
                              }
                            }
                          },
                          [_vm._v("click here")]
                        ),
                        _vm._v(" or modify your values "),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("setStep", 1)
                              }
                            }
                          },
                          [_vm._v("here")]
                        ),
                        _vm._v(".\n\t\t\t")
                      ])
                    : _c("div", { staticClass: "enquire-row no-print" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-red btn-enquire",
                            on: {
                              click: function($event) {
                                _vm.showEnquire = true
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("message.Enquire")))]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "Select (tick) channel size above and " +
                              _vm._s(_vm.$t("message.enquire")) +
                              " for more info (including blockage factors, pricing etc)."
                          )
                        ])
                      ])
                ]
          ],
          2
        )
      : _c("div", [
          _c("p", [
            _vm._v(
              "Please complete the registration form to immediately reveal the calculation."
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "registration-wrapper" }, [
            _c("div", { staticClass: "row center-md" }, [
              _c("div", { staticClass: "col-xs-12 col-md-4" }, [
                _c(
                  "div",
                  { staticClass: "box" },
                  [
                    _c("registration", {
                      ref: "regoform",
                      on: {
                        setCountry: _vm.setCountry,
                        success: function($event) {
                          _vm.registered = true
                          _vm.getResults()
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ]),
    _vm._v(" "),
    _vm.showEnquire
      ? _c(
          "div",
          {
            staticClass: "backdrop no-print",
            on: {
              click: function($event) {
                _vm.showEnquire = false
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "modal",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c("div", { staticClass: "header" }, [
                  _c("h3", [
                    _vm._v("Channel " + _vm._s(_vm.$t("message.Enquiry")))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "body" },
                  [
                    _c("enquire-form", {
                      attrs: {
                        results: _vm.selectedResults(),
                        units: _vm.lengthUnit,
                        submissionID: _vm.submissionID
                      },
                      on: {
                        success: function($event) {
                          _vm.enquiryComplete = true
                        },
                        close: function($event) {
                          _vm.showEnquire = false
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showResultsSent
      ? _c(
          "div",
          {
            staticClass: "backdrop no-print",
            on: {
              click: function($event) {
                _vm.showResultsSent = false
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "modal",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _vm._m(4),
                _vm._v(" "),
                _c("div", { staticClass: "body" }, [
                  _c("div", [
                    _c("div", { staticStyle: { "text-align": "center" } }, [
                      _c("p", [
                        _vm._v(
                          "We have sent a summary of your results to your email. Please feel free to contact ACO to discuss further."
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          on: {
                            click: function($event) {
                              _vm.showResultsSent = false
                            }
                          }
                        },
                        [_vm._v("Close")]
                      )
                    ])
                  ])
                ])
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "step-heading" }, [
      _c("span", [_vm._v("Step 4:")]),
      _vm._v(" Register & view results")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cta-row no-print" }, [
      _c("h2", [_vm._v("NEXT STEPS")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_vm._v("Happy with the result?"), _c("br"), _c("br")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "cta-row no-print",
        staticStyle: { "padding-top": "40px", "padding-bottom": "20px" }
      },
      [
        _c(
          "a",
          { staticClass: "btn btn-blue btn-enquire", attrs: { href: "/" } },
          [_vm._v("Size another run")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h3", [_vm._v("Results Sent")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }