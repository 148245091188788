import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VueI18n from 'vue-i18n';
import Calculator from './calculator.vue';

import 'es6-promise/auto';

Vue.use(VeeValidate);
Vue.use(VueI18n);

const messages = {
	'en-US': {
		message: {
			enquire: 'inquire',
			Enquire: 'Inquire',
			enquiry: 'inquiry',
			Enquiry: 'Inquiry',
			utilisation: 'utilization',
			Utilisation: 'Utilization',
		}
	},
	'en-AU': {
		message: {
			enquire: 'enquire',
			Enquire: 'Enquire',
			enquiry: 'enquiry',
			Enquiry: 'Enquiry',
			utilisation: 'utilisation',
			Utilisation: 'Utilisation',
		}
	}
};

const i18n = new VueI18n({
	locale: 'en-US', // set locale
	messages, // set locale messages
});

new Vue({
	i18n,
	el: '#calculator',
	render: h => h(Calculator, {
		props: {
			prefill: {
				rainfall: parseFloat(getURLParameter('rainfall')),
				units: getURLParameter('units'),
				area: parseFloat(getURLParameter('area')),
				length: parseFloat(getURLParameter('length')),
				slope: parseFloat(getURLParameter('slope')),
				width: parseInt(getURLParameter('width'))
			}
		}
	})
});

function getURLParameter(name) {
	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}