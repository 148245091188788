var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "water-run-off" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("p", [_vm._v("Calculated uniform lateral flow.")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-3 col-md-4" }, [
        _c("div", { staticClass: "box" }, [
          _vm._v(_vm._s(_vm.flowRate) + " " + _vm._s(_vm.flowUnitHuman()))
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-3 col-md-4" }, [
        _c("div", { staticClass: "box" }, [_vm._v(_vm._s(_vm.slope) + "%")])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12 col-md-offset-6 col-md-2" }, [
        _c("div", { staticClass: "box" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              staticStyle: { width: "100%" },
              on: {
                click: function($event) {
                  return _vm.$emit("next", 3)
                }
              }
            },
            [_vm._v("Next")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "step-heading" }, [
      _c("span", [_vm._v("Step 2:")]),
      _vm._v(" Surface water run-off")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-3 col-md-4" }, [
      _c("div", { staticClass: "box relative" }, [
        _vm._v("Lateral Intake\n\t\t\t\t"),
        _c("img", { staticClass: "info", attrs: { src: "/icons/info.svg" } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "info-text", staticStyle: { width: "300px" } },
          [
            _vm._v(
              "\n\t\t\t\t\tLateral Intake = Design Discharge / Length of Drain\n\t\t\t\t"
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-3 col-md-4" }, [
      _c("div", { staticClass: "box" }, [_vm._v("Longitudinal Slope")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }