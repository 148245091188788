<template>
	<div class="catchment">
		<h2 class="step-heading"><span>Step 1:</span> Catchment hydraulics</h2>
		<p>Provide us with the initial values so we can determine the flow rate and speed of the surface water run-off.</p>

		<div class="row">
			<div class="col-xs-1 col-md-1"></div>
			<div class="col-xs-11 col-md-4">
				<div class="box relative">Units
					<img class="info" src="/icons/info.svg" />
					<div class="info-text">
						<div></div>
						HYDROlite can accept inputs and display results using the metric system (metres, litres) or imperial units (feet, gallons)
					</div>
				</div>
			</div>
			<div class="col-xs-6 col-md-5">
				<select
					:value="units"
					@change="checkFirstUnit('units', $event.target.value)"
					:class="{ placeholder: units == ''}"
					v-validate="'required'"
					name="units">
					<option disabled value="">Select</option>
					<option value="m">Metric (metres, litres)</option>
					<option value="i">Imperial (feet, gallons)</option>
				</select>
				<div class="vee-err" v-show="errors.has('units')">Required</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-1 col-md-1"></div>
			<div class="col-xs-11 col-md-4">
				<div class="box relative">Run Name
					<img class="info" src="/icons/info.svg" />
					<div class="info-text">
						<div></div>
						You can specify a run name for your own reference (e.g. trench drain 1)
						in case you need to produce multiple runs
					</div>
				</div>
			</div>
			<div class="col-xs-6 col-md-5">
				<input
					type="text"
					:value="label"
					@input="$emit('update:label', $event.target.value == '' ? '' : $event.target.value)"
					name="label" />
			</div>
		</div>

		<p v-show="unitsSelected()">Fill in Part 1 or Part 2, then Part 3.</p>

		<div class="row" v-show="unitsSelected()">
			<div class="col-xs-1 col-md-1">1.</div>
			<div class="col-xs-11 col-md-4">
				<div class="box relative">Drainage Area
					<img class="info" src="/icons/info.svg" />
					<div class="info-text">
						<div><img src="/images/drainage-area.jpg" alt=""></div>
						Run length (to outlet) x catchment width
					</div>
				</div>
			</div>
			<div class="col-xs-6 col-md-5">
				<input name="area"
					type="number"
					:placeholder="!dischargeOverride ? 'Enter value' : '(Manual design discharge)'"
					:value="!dischargeOverride ? area : null"
					@input="$emit('update:area', $event.target.value == '' ? null : Number($event.target.value))"
					:readonly="dischargeOverride" />
				<div class="vee-err" v-show="isAreaRequired()">This field is required</div>
			</div>
			<div class="col-xs-6 col-md-2">
				{{ areaUnitDisplay() }}
			</div>
		</div>

		<div class="row" v-show="unitsSelected()">
			<div class="col-xs-1 col-md-1"></div>
			<div class="col-xs-12 col-md-4">
				<div class="box relative">Rainfall Intensity
					<img class="info" src="/icons/info.svg" />
					<div class="info-text">
						<div><img src="/images/rainfall.jpg" alt=""></div>
						Refer to your local rainfall map
					</div>
				</div>
			</div>
			<div class="col-xs-6 col-md-5">
				<input name="rainfall"
					type="number"
					:placeholder="!dischargeOverride ? 'Enter value' : '(Manual design discharge)'"
					:value="!dischargeOverride ? rainfall : null"
					@input="$emit('update:rainfall', $event.target.value == '' ? null : Number($event.target.value))"
					:readonly="dischargeOverride" />
				<div class="vee-err" v-show="isRainfallRequired()">This field is required</div>
				<div style="margin-top: 5px; font-size: 11px;">Runoff Coefficient (C) assumed to be 1.0.  If required, design discharge can be overwritten in Part 2.</div>
			</div>
			<div class="col-xs-6 col-md-2">
				{{ rainfallUnitDisplay() }}
			</div>
		</div>

		<hr class="calculator-divider" v-show="unitsSelected()" />

		<div class="row" v-show="unitsSelected()">
			<div class="col-xs-1 col-md-1">2.</div>
			<div class="col-xs-12 col-md-4">
				<div class="box relative">Design Discharge
					<img class="info" src="/icons/info.svg" />
					<div class="info-text">
						<div><img src="/images/discharge.png" alt=""></div>
						Design flow rate spilling into the pit/catch basin
						from the channel draining the catchment
					</div>
				</div>
			</div>
			<div class="col-xs-6 col-md-4">
				<input
					type="number"
					placeholder="Enter value"
					:value="discharge"
					@input="$emit('update:discharge', $event.target.value == '' ? null : Number($event.target.value))"
					v-validate="'required'"
					name="discharge"/>
				<div class="vee-err" v-show="errors.has('discharge')">{{ errors.first('discharge') }}</div>
				<!-- <div class="field-notice" v-show="!!discharge">Or enter an alternative value</div> -->
				<div style="margin-top: 5px; font-size: 11px;">Calculated value: you may override as needed</div>
			</div>
			<div class="col-xs-6 col-md-3">
				{{ dischargeUnitDisplay() }}
				<span v-if="dischargeUnit == 'i'" style="white-space: nowrap;">({{ dischargeInCfm() }} CFS)</span>
			</div>
		</div>
		
		<hr class="calculator-divider" v-show="unitsSelected()" />

		<div class="row" v-show="unitsSelected()">
			<div class="col-xs-1 col-md-1">3.</div>
			<div class="col-xs-12 col-md-4">
				<div class="box relative">Length of Drain to Outlet
					<img class="info" src="/icons/info.svg" />
					<div class="info-text">
						<div><img src="/images/length1.jpg" alt=""></div>
						This is the length of channel drain to a single outlet 
						i.e. longest distance liquid could travel in channel
					</div>
				</div>
			</div>
			<div class="col-xs-6 col-md-5">
				<select name="length"
					:value="length"
					@change="$emit('update:length', $event.target.value == '' ? null : Number($event.target.value))"
					v-validate="'required'">
					<option disabled selected value="">Select</option>
					<option value="999">Less than {{ lengthLabel(3) }}</option>
					<option :value="lengthValue(3)">{{ lengthLabel(3) }}</option>
					<option :value="lengthValue(4)">{{ lengthLabel(4) }}</option>
					<option :value="lengthValue(5)">{{ lengthLabel(5) }}</option>
					<option :value="lengthValue(6)">{{ lengthLabel(6) }}</option>
					<option :value="lengthValue(7)">{{ lengthLabel(7) }}</option>
					<option :value="lengthValue(8)">{{ lengthLabel(8) }}</option>
					<option :value="lengthValue(9)">{{ lengthLabel(9) }}</option>
					<option :value="lengthValue(10)">{{ lengthLabel(10) }}</option>
					<option :value="lengthValue(11)">{{ lengthLabel(11) }}</option>
					<option :value="lengthValue(12)">{{ lengthLabel(12) }}</option>
					<option :value="lengthValue(13)">{{ lengthLabel(13) }}</option>
					<option :value="lengthValue(14)">{{ lengthLabel(14) }}</option>
					<option :value="lengthValue(15)">{{ lengthLabel(15) }}</option>
					<option :value="lengthValue(16)">{{ lengthLabel(16) }}</option>
					<option :value="lengthValue(17)">{{ lengthLabel(17) }}</option>
					<option :value="lengthValue(18)">{{ lengthLabel(18) }}</option>
					<option :value="lengthValue(19)">{{ lengthLabel(19) }}</option>
					<option :value="lengthValue(20)">{{ lengthLabel(20) }}</option>
					<option :value="lengthValue(21)">{{ lengthLabel(21) }}</option>
					<option :value="lengthValue(22)">{{ lengthLabel(22) }}</option>
					<option :value="lengthValue(23)">{{ lengthLabel(23) }}</option>
					<option :value="lengthValue(24)">{{ lengthLabel(24) }}</option>
					<option :value="lengthValue(25)">{{ lengthLabel(25) }}</option>
					<option :value="lengthValue(26)">{{ lengthLabel(26) }}</option>
					<option :value="lengthValue(27)">{{ lengthLabel(27) }}</option>
					<option :value="lengthValue(28)">{{ lengthLabel(28) }}</option>
					<option :value="lengthValue(29)">{{ lengthLabel(29) }}</option>
					<option :value="lengthValue(30)">{{ lengthLabel(30) }}</option>
					<option :value="lengthValue(31)">{{ lengthLabel(31) }}</option>
					<option :value="lengthValue(32)">{{ lengthLabel(32) }}</option>
					<option :value="lengthValue(33)">{{ lengthLabel(33) }}</option>
					<option :value="lengthValue(34)">{{ lengthLabel(34) }}</option>
					<option :value="lengthValue(35)">{{ lengthLabel(35) }}</option>
					<option :value="lengthValue(36)">{{ lengthLabel(36) }}</option>
					<option :value="lengthValue(37)">{{ lengthLabel(37) }}</option>
					<option :value="lengthValue(38)">{{ lengthLabel(38) }}</option>
					<option :value="lengthValue(39)">{{ lengthLabel(39) }}</option>
					<option :value="lengthValue(40)">{{ lengthLabel(40) }}</option>
					<option value="999">More than {{ lengthLabel(40) }}</option>
				</select>

				<div class="vee-err" v-show="errors.has('length')">{{ errors.first('length') }}</div>
				<div class="field-notice" v-show="length == 999">{{ outOfScopeMessage }}</div>
			</div>
			<div class="col-xs-6 col-md-2">
			</div>
		</div>

		<div class="row" v-show="unitsSelected()">
			<div class="col-xs-1 col-md-1"></div>
			<div class="col-xs-12 col-md-4">
				<div class="box relative">Longitudinal Slope
					<img class="info" src="/icons/info.svg" />
					<div class="info-text">
						<div><img src="/images/slope.jpg" alt=""></div>
						Longitudinal ground slope % - Ground fall along channel drain 
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-md-5">
				<select name="slope" 
					:value="slope"
					:class="{ placeholder: slope == null}"
					@input="$emit('update:slope', $event.target.value == '' ? null : Number($event.target.value))"
					v-validate="'required'">
					<option disabled selected value="">Select</option>
					<option value="0">0%</option>
					<option value="0.5">0.5%</option>
					<option value="999">Other</option>
				</select>
				<div class="vee-err" v-show="errors.has('slope')">{{ errors.first('slope') }}</div>
				<div class="field-notice" v-show="slope == 999">{{ outOfScopeMessage }}</div>
			</div>
		</div>

		<div class="row" v-show="unitsSelected()">
			<div class="col-xs-12 col-md-2">
				<div class="box">
					<button class="btn" style="width: 100%; background-color: #999" @click="reset()">Start again</button>
				</div>
			</div>
			<div class="col-xs-12 col-md-offset-8 col-md-2">
				<div class="box">
					<button class="btn" style="width: 100%" @click="nextPage()">Next</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import VeeValidate from 'vee-validate';
import convert from 'convert-units';
import * as Helper from '../helper.js';

export default {
	props: {
		units: String,
		rainfall: Number,
		rainfallUnit: String,
		area: Number,
		areaUnit: String,
		length: Number,
		lengthUnit: String,
		discharge: Number,
		dischargeUnit: String,
		dischargeManual: Number,
		dischargeOverride: Boolean,
		slope: Number,
		label: String
	},
	data: function() {
		return {
			outOfScopeMessage: "This input is beyond the scope of this program. To ensure your precise catchment data is used, select your region in the \"Contact ACO\" panel on this page then \"Request hydraulic proposal\". On submission, a free documented channel recommendation proposal will be emailed to you by one of ACO's technical support team.",
			nextAttempted: false
		}
	},
	methods: {
		dischargeInCfm() {
			// Gets displayed for imperial only
			return Helper.roundTo(this.discharge * 0.002228, 4)
		},
		unitsSelected() {
			return Boolean(this.units != '' && this.units != null)
		},
		isAreaRequired() {
			return (this.nextAttempted && !this.area && !this.dischargeManual);
		},
		isRainfallRequired() {
			return (this.nextAttempted && !this.rainfall && !this.dischargeManual);
		},
		lengthLabel(metres) {
			let length = convert(metres).from('m').to(this.lengthUnitDisplay())
			let unit = this.lengthUnitDisplay()
			if (this.units == "i") {
				return `${Helper.roundTo(length, 2)} ${unit} (${metres} m)`
			} else {
				return `${Helper.roundTo(length, 2)} ${unit}`
			}
		},
		lengthValue(metres) {
			let length = convert(metres).from('m').to(this.lengthUnitDisplay())
			return `${Helper.roundTo(length, 2)}`
		},
		areaUnitDisplay() {
			return (this.units == "i") ? 'ft²' : 'm²'
		},
		rainfallUnitDisplay() {
			return (this.units == "i") ? 'inch/hr' : 'mm/hr'
		},
		dischargeUnitDisplay() {
			return (this.units == "i") ? 'GPM' : 'L/s'
		},
		lengthUnitDisplay() {
			return (this.units == "i") ? 'ft' : 'm'
		},
		nextPage: function() {
			this.nextAttempted = true;
			if (this.slope == 999 || this.length == 999) {
				return false;
			}
			if (this.isAreaRequired() || this.isRainfallRequired()) {
				return false;
			}
			this.$validator.validateAll().then((result) => {
				if (result) {
					this.$emit('next', 2);
				}
			});
		},
		checkFirstUnit: function(unit, value){
			this.$emit(`update:${unit}`, value)
			this.$emit('setAllUnits', value)
		},
		reset: function() {
			window.location = '/';
		}
	}
}
</script>
