var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact-aco" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("p", { staticStyle: { margin: "0 0 5px" } }, [
        _vm._v("Select a region to " + _vm._s(_vm.$t("message.enquire")))
      ]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.region,
              expression: "region"
            }
          ],
          class: { placeholder: _vm.region == "" },
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.region = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              function($event) {
                return _vm.$emit("update:region", $event.target.value)
              }
            ]
          }
        },
        [
          _c("option", { attrs: { disabled: "", selected: "", value: "" } }, [
            _vm._v("Region")
          ]),
          _vm._v(" "),
          _c("option", [_vm._v("Australia")]),
          _vm._v(" "),
          _c("option", [_vm._v("Canada")]),
          _vm._v(" "),
          _c("option", [_vm._v("Mexico")]),
          _vm._v(" "),
          _c("option", [_vm._v("New Zealand")]),
          _vm._v(" "),
          _c("option", [_vm._v("USA")]),
          _vm._v(" "),
          _c("option", [_vm._v("Vietnam")]),
          _vm._v(" "),
          _c("option", { attrs: { disabled: "", value: "" } }, [
            _vm._v("----------------")
          ]),
          _vm._v(" "),
          _c("option", [_vm._v("Anguilla")]),
          _vm._v(" "),
          _c("option", [_vm._v("Antigua Barbuda")]),
          _vm._v(" "),
          _c("option", [_vm._v("Aruba")]),
          _vm._v(" "),
          _c("option", [_vm._v("Australia")]),
          _vm._v(" "),
          _c("option", [_vm._v("Barbados")]),
          _vm._v(" "),
          _c("option", [_vm._v("Belize")]),
          _vm._v(" "),
          _c("option", [_vm._v("Bermuda")]),
          _vm._v(" "),
          _c("option", [_vm._v("Brunei Darussalam")]),
          _vm._v(" "),
          _c("option", [_vm._v("Cambodia")]),
          _vm._v(" "),
          _c("option", [_vm._v("Canada")]),
          _vm._v(" "),
          _c("option", [_vm._v("Costa Rica")]),
          _vm._v(" "),
          _c("option", [_vm._v("Cuba")]),
          _vm._v(" "),
          _c("option", [_vm._v("Curacao")]),
          _vm._v(" "),
          _c("option", [_vm._v("Dominican Republic")]),
          _vm._v(" "),
          _c("option", [_vm._v("Fiji")]),
          _vm._v(" "),
          _c("option", [_vm._v("Grenada")]),
          _vm._v(" "),
          _c("option", [_vm._v("Guam")]),
          _vm._v(" "),
          _c("option", [_vm._v("Guatemala")]),
          _vm._v(" "),
          _c("option", [_vm._v("Haiti")]),
          _vm._v(" "),
          _c("option", [_vm._v("Honduras")]),
          _vm._v(" "),
          _c("option", [_vm._v("Indonesia")]),
          _vm._v(" "),
          _c("option", [_vm._v("Jamaica")]),
          _vm._v(" "),
          _c("option", [_vm._v("Kiribati")]),
          _vm._v(" "),
          _c("option", [_vm._v("Laos")]),
          _vm._v(" "),
          _c("option", [_vm._v("Malaysia")]),
          _vm._v(" "),
          _c("option", [_vm._v("Marshall Islands")]),
          _vm._v(" "),
          _c("option", [_vm._v("Mexico")]),
          _vm._v(" "),
          _c("option", [_vm._v("Micronesia")]),
          _vm._v(" "),
          _c("option", [_vm._v("Myanmar (Burma)")]),
          _vm._v(" "),
          _c("option", [_vm._v("Nauru")]),
          _vm._v(" "),
          _c("option", [_vm._v("New Zealand")]),
          _vm._v(" "),
          _c("option", [_vm._v("Nicaragua")]),
          _vm._v(" "),
          _c("option", [_vm._v("Palau")]),
          _vm._v(" "),
          _c("option", [_vm._v("Panama")]),
          _vm._v(" "),
          _c("option", [_vm._v("Papua New Guinea")]),
          _vm._v(" "),
          _c("option", [_vm._v("Philippines")]),
          _vm._v(" "),
          _c("option", [_vm._v("Puerto Rico")]),
          _vm._v(" "),
          _c("option", [_vm._v("Sint Maarten")]),
          _vm._v(" "),
          _c("option", [_vm._v("Samoa")]),
          _vm._v(" "),
          _c("option", [_vm._v("Singapore")]),
          _vm._v(" "),
          _c("option", [_vm._v("Solomon Islands")]),
          _vm._v(" "),
          _c("option", [_vm._v("St. Kitts - Nevis")]),
          _vm._v(" "),
          _c("option", [_vm._v("St. Lucia")]),
          _vm._v(" "),
          _c("option", [_vm._v("St. Vincent - Grenadines")]),
          _vm._v(" "),
          _c("option", [_vm._v("Thailand")]),
          _vm._v(" "),
          _c("option", [_vm._v("Tonga")]),
          _vm._v(" "),
          _c("option", [_vm._v("Tonga")]),
          _vm._v(" "),
          _c("option", [_vm._v("Trinidad and Tobago")]),
          _vm._v(" "),
          _c("option", [_vm._v("Turks & Caicos")]),
          _vm._v(" "),
          _c("option", [_vm._v("Tuvalu")]),
          _vm._v(" "),
          _c("option", [_vm._v("USA")]),
          _vm._v(" "),
          _c("option", [_vm._v("Vanuatu")]),
          _vm._v(" "),
          _c("option", [_vm._v("Vietnam")]),
          _vm._v(" "),
          _c("option", [_vm._v("Virgin Islands")])
        ]
      ),
      _vm._v(" "),
      _vm.region
        ? _c("div", [
            _vm.showUSA()
              ? _c("div", { staticClass: "contact-info" }, [
                  _c("h3", [_vm._v("USA")]),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _vm._m(2),
                  _vm._v(" "),
                  _vm._m(3)
                ])
              : _vm.showNewZealand()
              ? _c("div", { staticClass: "contact-info" }, [
                  _c("h3", [_vm._v("New Zealand")]),
                  _vm._v(" "),
                  _vm._m(4),
                  _vm._v(" "),
                  _vm._m(5),
                  _vm._v(" "),
                  _vm._m(6)
                ])
              : _vm.showCanada()
              ? _c("div", { staticClass: "contact-info" }, [
                  _c("h3", [_vm._v("Canada")]),
                  _vm._v(" "),
                  _vm._m(7),
                  _vm._v(" "),
                  _vm._m(8),
                  _vm._v(" "),
                  _vm._m(9)
                ])
              : _vm.showMexico()
              ? _c("div", { staticClass: "contact-info" }, [
                  _c("h3", [_vm._v("Mexico")]),
                  _vm._v(" "),
                  _vm._m(10),
                  _vm._v(" "),
                  _vm._m(11),
                  _vm._v(" "),
                  _vm._m(12)
                ])
              : _vm.showAustralia()
              ? _c("div", { staticClass: "contact-info" }, [
                  _c("h3", [_vm._v("Australia")]),
                  _vm._v(" "),
                  _vm._m(13),
                  _vm._v(" "),
                  _vm._m(14),
                  _vm._v(" "),
                  _vm._m(15)
                ])
              : _vm.showVietnam()
              ? _c("div", { staticClass: "contact-info" }, [
                  _c("h3", [_vm._v("Vietnam")]),
                  _vm._v(" "),
                  _vm._m(16),
                  _vm._v(" "),
                  _vm._m(17),
                  _vm._v(" "),
                  _vm._m(18)
                ])
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h2", [_vm._v("Contact ACO")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        { staticClass: "no-link-style", attrs: { href: "tel:1 800-543-4764" } },
        [_vm._v("1 800 543 4764")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("a", { attrs: { href: "mailto:info@acousa.com" } }, [
        _vm._v("info@acousa.com")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.acousa.com/service/drain-design-form",
            target: "_blank"
          }
        },
        [_vm._v("Request hydraulic proposal")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        { staticClass: "no-link-style", attrs: { href: "tel:0800-448-080" } },
        [_vm._v("0800 448 080")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("a", { attrs: { href: "mailto:info@aconz.co.nz" } }, [
        _vm._v("info@aconz.co.nz")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.aconz.co.nz/technical/trench-drain-design-form",
            target: "_blank"
          }
        },
        [_vm._v("Request hydraulic proposal")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        { staticClass: "no-link-style", attrs: { href: "tel:877-226-4255" } },
        [_vm._v("877 226 4255")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("a", { attrs: { href: "mailto:info@acocan.ca" } }, [
        _vm._v("info@acocan.ca")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href:
              "https://www.acocan.ca/technical/trench-drain-design-request-form",
            target: "_blank"
          }
        },
        [_vm._v("Request hydraulic proposal")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        { staticClass: "no-link-style", attrs: { href: "tel:(55)-5344-8547" } },
        [_vm._v("(55) 5344 8547")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("a", { attrs: { href: "mailto:info@aco.mx" } }, [
        _vm._v("info@aco.mx")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href:
              "https://www.aco.mx/servicio-tecnico/solicitud-de-diseno-de-trinchera",
            target: "_blank"
          }
        },
        [_vm._v("Request hydraulic proposal")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        { staticClass: "no-link-style", attrs: { href: "tel:1300-765-226" } },
        [_vm._v("1300 765 226")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("a", { attrs: { href: "mailto:sales@acoaus.com.au" } }, [
        _vm._v("sales@acoaus.com.au")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href:
              "https://www.acodrain.com.au/product-support/request-design-assistance/",
            target: "_blank"
          }
        },
        [_vm._v("Request hydraulic proposal")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        {
          staticClass: "no-link-style",
          attrs: { href: "tel:+84-90-364-4625" }
        },
        [_vm._v("+84 90 364 4625")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("a", { attrs: { href: "mailto:vuxuan.tran@aco.com" } }, [
        _vm._v("vuxuan.tran@aco.com")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href: "http://acovietnam.com/drain-design-form/",
            target: "_blank"
          }
        },
        [_vm._v("Request hydraulic proposal")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }