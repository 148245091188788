<template>
	<div class="relevent-system">
		<h2 class="step-heading"><span>Step 3:</span> Identifying relevant system</h2>
		<p v-if="!preselected">Choose a trench drain width.</p>
		<p v-else>The fields below have been pre-filled based on the preselected grate.</p>

		<div class="row">
			<div class="col-xs-12 col-md-4">
				<div class="box relative">Internal Channel Width 
					<img class="info" src="/icons/info.svg" />
					<div class="info-text">
						Hydraulic capacities increase with channel widths
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-md-6">
				<select
					:value="channelWidth"
					@change="$emit('update:channelWidth', $event.target.value == '' ? null : Number($event.target.value))"
					:class="{ placeholder: channelWidth == null}"
					v-validate="'required'"
					name="channelWidth"
					:disabled="preselected">
					<option disabled selected value="">Select</option>
					<option value="100">100mm (4")</option>
					<option value="200">200mm (8")</option>
					<option value="300">300mm (12")</option>
				</select>
				<div class="vee-err" v-show="errors.has('channelWidth')">{{ errors.first('channelWidth') }}</div>
			</div>
		</div>

		<div class="row">
			<div style="margin-right: 20px">
				<img src="/images/100.gif" alt="100mm" style=""
					:class="channelWidth == 100 ? 'selected-border' : ''"
					@click="$emit('update:channelWidth', 100)">
			</div>
			<div style="margin-right: 20px">
				<img src="/images/200.gif" alt="200mm" style=""
				:class="channelWidth == 200 ? 'selected-border' : ''"
				@click="$emit('update:channelWidth', 200)">
			</div>
			<div>
				<img src="/images/300.gif" alt="300mm" style=""
				:class="channelWidth == 300 ? 'selected-border' : ''"
				@click="$emit('update:channelWidth', 300)">
			</div>
		</div>

		<div class="row">
			<div class="col-xs-12 col-md-offset-8 col-md-2">
				<div class="box">
					<button class="btn" style="width: 100%" @click="nextPage()">Next</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		channelWidth: Number,
		preselected: String
	},
	methods: {
		updateDrain(e){
			this.$emit('update:channelWidth', null);
		},
		nextPage: function() {
			this.$validator.validateAll().then((result) => {
				if (result) {
					this.$emit('next', 4);
				}
			});
		}
	}
}
</script>