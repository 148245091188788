var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "catchment" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Provide us with the initial values so we can determine the flow rate and speed of the surface water run-off."
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-1 col-md-1" }),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-6 col-md-5" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            class: { placeholder: _vm.units == "" },
            attrs: { name: "units" },
            domProps: { value: _vm.units },
            on: {
              change: function($event) {
                return _vm.checkFirstUnit("units", $event.target.value)
              }
            }
          },
          [
            _c("option", { attrs: { disabled: "", value: "" } }, [
              _vm._v("Select")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "m" } }, [
              _vm._v("Metric (metres, litres)")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "i" } }, [
              _vm._v("Imperial (feet, gallons)")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("units"),
                expression: "errors.has('units')"
              }
            ],
            staticClass: "vee-err"
          },
          [_vm._v("Required")]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-1 col-md-1" }),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-6 col-md-5" }, [
        _c("input", {
          attrs: { type: "text", name: "label" },
          domProps: { value: _vm.label },
          on: {
            input: function($event) {
              return _vm.$emit(
                "update:label",
                $event.target.value == "" ? "" : $event.target.value
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c(
      "p",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.unitsSelected(),
            expression: "unitsSelected()"
          }
        ]
      },
      [_vm._v("Fill in Part 1 or Part 2, then Part 3.")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.unitsSelected(),
            expression: "unitsSelected()"
          }
        ],
        staticClass: "row"
      },
      [
        _c("div", { staticClass: "col-xs-1 col-md-1" }, [_vm._v("1.")]),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-6 col-md-5" }, [
          _c("input", {
            attrs: {
              name: "area",
              type: "number",
              placeholder: !_vm.dischargeOverride
                ? "Enter value"
                : "(Manual design discharge)",
              readonly: _vm.dischargeOverride
            },
            domProps: { value: !_vm.dischargeOverride ? _vm.area : null },
            on: {
              input: function($event) {
                _vm.$emit(
                  "update:area",
                  $event.target.value == "" ? null : Number($event.target.value)
                )
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isAreaRequired(),
                  expression: "isAreaRequired()"
                }
              ],
              staticClass: "vee-err"
            },
            [_vm._v("This field is required")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-6 col-md-2" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.areaUnitDisplay()) + "\n\t\t")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.unitsSelected(),
            expression: "unitsSelected()"
          }
        ],
        staticClass: "row"
      },
      [
        _c("div", { staticClass: "col-xs-1 col-md-1" }),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-6 col-md-5" }, [
          _c("input", {
            attrs: {
              name: "rainfall",
              type: "number",
              placeholder: !_vm.dischargeOverride
                ? "Enter value"
                : "(Manual design discharge)",
              readonly: _vm.dischargeOverride
            },
            domProps: { value: !_vm.dischargeOverride ? _vm.rainfall : null },
            on: {
              input: function($event) {
                _vm.$emit(
                  "update:rainfall",
                  $event.target.value == "" ? null : Number($event.target.value)
                )
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isRainfallRequired(),
                  expression: "isRainfallRequired()"
                }
              ],
              staticClass: "vee-err"
            },
            [_vm._v("This field is required")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "5px", "font-size": "11px" } },
            [
              _vm._v(
                "Runoff Coefficient (C) assumed to be 1.0.  If required, design discharge can be overwritten in Part 2."
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-6 col-md-2" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.rainfallUnitDisplay()) + "\n\t\t")
        ])
      ]
    ),
    _vm._v(" "),
    _c("hr", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.unitsSelected(),
          expression: "unitsSelected()"
        }
      ],
      staticClass: "calculator-divider"
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.unitsSelected(),
            expression: "unitsSelected()"
          }
        ],
        staticClass: "row"
      },
      [
        _c("div", { staticClass: "col-xs-1 col-md-1" }, [_vm._v("2.")]),
        _vm._v(" "),
        _vm._m(5),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-6 col-md-4" }, [
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            attrs: {
              type: "number",
              placeholder: "Enter value",
              name: "discharge"
            },
            domProps: { value: _vm.discharge },
            on: {
              input: function($event) {
                _vm.$emit(
                  "update:discharge",
                  $event.target.value == "" ? null : Number($event.target.value)
                )
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("discharge"),
                  expression: "errors.has('discharge')"
                }
              ],
              staticClass: "vee-err"
            },
            [_vm._v(_vm._s(_vm.errors.first("discharge")))]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "5px", "font-size": "11px" } },
            [_vm._v("Calculated value: you may override as needed")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-6 col-md-3" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.dischargeUnitDisplay()) + "\n\t\t\t"),
          _vm.dischargeUnit == "i"
            ? _c("span", { staticStyle: { "white-space": "nowrap" } }, [
                _vm._v("(" + _vm._s(_vm.dischargeInCfm()) + " CFS)")
              ])
            : _vm._e()
        ])
      ]
    ),
    _vm._v(" "),
    _c("hr", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.unitsSelected(),
          expression: "unitsSelected()"
        }
      ],
      staticClass: "calculator-divider"
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.unitsSelected(),
            expression: "unitsSelected()"
          }
        ],
        staticClass: "row"
      },
      [
        _c("div", { staticClass: "col-xs-1 col-md-1" }, [_vm._v("3.")]),
        _vm._v(" "),
        _vm._m(6),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-6 col-md-5" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: { name: "length" },
              domProps: { value: _vm.length },
              on: {
                change: function($event) {
                  _vm.$emit(
                    "update:length",
                    $event.target.value == ""
                      ? null
                      : Number($event.target.value)
                  )
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { disabled: "", selected: "", value: "" } },
                [_vm._v("Select")]
              ),
              _vm._v(" "),
              _c("option", { attrs: { value: "999" } }, [
                _vm._v("Less than " + _vm._s(_vm.lengthLabel(3)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(3) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(3)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(4) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(4)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(5) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(5)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(6) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(6)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(7) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(7)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(8) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(8)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(9) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(9)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(10) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(10)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(11) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(11)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(12) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(12)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(13) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(13)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(14) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(14)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(15) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(15)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(16) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(16)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(17) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(17)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(18) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(18)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(19) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(19)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(20) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(20)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(21) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(21)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(22) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(22)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(23) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(23)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(24) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(24)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(25) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(25)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(26) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(26)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(27) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(27)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(28) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(28)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(29) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(29)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(30) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(30)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(31) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(31)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(32) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(32)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(33) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(33)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(34) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(34)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(35) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(35)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(36) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(36)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(37) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(37)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(38) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(38)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(39) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(39)))
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: _vm.lengthValue(40) } }, [
                _vm._v(_vm._s(_vm.lengthLabel(40)))
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "999" } }, [
                _vm._v("More than " + _vm._s(_vm.lengthLabel(40)))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("length"),
                  expression: "errors.has('length')"
                }
              ],
              staticClass: "vee-err"
            },
            [_vm._v(_vm._s(_vm.errors.first("length")))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.length == 999,
                  expression: "length == 999"
                }
              ],
              staticClass: "field-notice"
            },
            [_vm._v(_vm._s(_vm.outOfScopeMessage))]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-6 col-md-2" })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.unitsSelected(),
            expression: "unitsSelected()"
          }
        ],
        staticClass: "row"
      },
      [
        _c("div", { staticClass: "col-xs-1 col-md-1" }),
        _vm._v(" "),
        _vm._m(7),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-md-5" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              class: { placeholder: _vm.slope == null },
              attrs: { name: "slope" },
              domProps: { value: _vm.slope },
              on: {
                input: function($event) {
                  _vm.$emit(
                    "update:slope",
                    $event.target.value == ""
                      ? null
                      : Number($event.target.value)
                  )
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { disabled: "", selected: "", value: "" } },
                [_vm._v("Select")]
              ),
              _vm._v(" "),
              _c("option", { attrs: { value: "0" } }, [_vm._v("0%")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "0.5" } }, [_vm._v("0.5%")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "999" } }, [_vm._v("Other")])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("slope"),
                  expression: "errors.has('slope')"
                }
              ],
              staticClass: "vee-err"
            },
            [_vm._v(_vm._s(_vm.errors.first("slope")))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.slope == 999,
                  expression: "slope == 999"
                }
              ],
              staticClass: "field-notice"
            },
            [_vm._v(_vm._s(_vm.outOfScopeMessage))]
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.unitsSelected(),
            expression: "unitsSelected()"
          }
        ],
        staticClass: "row"
      },
      [
        _c("div", { staticClass: "col-xs-12 col-md-2" }, [
          _c("div", { staticClass: "box" }, [
            _c(
              "button",
              {
                staticClass: "btn",
                staticStyle: { width: "100%", "background-color": "#999" },
                on: {
                  click: function($event) {
                    return _vm.reset()
                  }
                }
              },
              [_vm._v("Start again")]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-md-offset-8 col-md-2" }, [
          _c("div", { staticClass: "box" }, [
            _c(
              "button",
              {
                staticClass: "btn",
                staticStyle: { width: "100%" },
                on: {
                  click: function($event) {
                    return _vm.nextPage()
                  }
                }
              },
              [_vm._v("Next")]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "step-heading" }, [
      _c("span", [_vm._v("Step 1:")]),
      _vm._v(" Catchment hydraulics")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-11 col-md-4" }, [
      _c("div", { staticClass: "box relative" }, [
        _vm._v("Units\n\t\t\t\t"),
        _c("img", { staticClass: "info", attrs: { src: "/icons/info.svg" } }),
        _vm._v(" "),
        _c("div", { staticClass: "info-text" }, [
          _c("div"),
          _vm._v(
            "\n\t\t\t\t\tHYDROlite can accept inputs and display results using the metric system (metres, litres) or imperial units (feet, gallons)\n\t\t\t\t"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-11 col-md-4" }, [
      _c("div", { staticClass: "box relative" }, [
        _vm._v("Run Name\n\t\t\t\t"),
        _c("img", { staticClass: "info", attrs: { src: "/icons/info.svg" } }),
        _vm._v(" "),
        _c("div", { staticClass: "info-text" }, [
          _c("div"),
          _vm._v(
            "\n\t\t\t\t\tYou can specify a run name for your own reference (e.g. trench drain 1)\n\t\t\t\t\tin case you need to produce multiple runs\n\t\t\t\t"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-11 col-md-4" }, [
      _c("div", { staticClass: "box relative" }, [
        _vm._v("Drainage Area\n\t\t\t\t"),
        _c("img", { staticClass: "info", attrs: { src: "/icons/info.svg" } }),
        _vm._v(" "),
        _c("div", { staticClass: "info-text" }, [
          _c("div", [
            _c("img", { attrs: { src: "/images/drainage-area.jpg", alt: "" } })
          ]),
          _vm._v(
            "\n\t\t\t\t\tRun length (to outlet) x catchment width\n\t\t\t\t"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 col-md-4" }, [
      _c("div", { staticClass: "box relative" }, [
        _vm._v("Rainfall Intensity\n\t\t\t\t"),
        _c("img", { staticClass: "info", attrs: { src: "/icons/info.svg" } }),
        _vm._v(" "),
        _c("div", { staticClass: "info-text" }, [
          _c("div", [
            _c("img", { attrs: { src: "/images/rainfall.jpg", alt: "" } })
          ]),
          _vm._v("\n\t\t\t\t\tRefer to your local rainfall map\n\t\t\t\t")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 col-md-4" }, [
      _c("div", { staticClass: "box relative" }, [
        _vm._v("Design Discharge\n\t\t\t\t"),
        _c("img", { staticClass: "info", attrs: { src: "/icons/info.svg" } }),
        _vm._v(" "),
        _c("div", { staticClass: "info-text" }, [
          _c("div", [
            _c("img", { attrs: { src: "/images/discharge.png", alt: "" } })
          ]),
          _vm._v(
            "\n\t\t\t\t\tDesign flow rate spilling into the pit/catch basin\n\t\t\t\t\tfrom the channel draining the catchment\n\t\t\t\t"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 col-md-4" }, [
      _c("div", { staticClass: "box relative" }, [
        _vm._v("Length of Drain to Outlet\n\t\t\t\t"),
        _c("img", { staticClass: "info", attrs: { src: "/icons/info.svg" } }),
        _vm._v(" "),
        _c("div", { staticClass: "info-text" }, [
          _c("div", [
            _c("img", { attrs: { src: "/images/length1.jpg", alt: "" } })
          ]),
          _vm._v(
            "\n\t\t\t\t\tThis is the length of channel drain to a single outlet \n\t\t\t\t\ti.e. longest distance liquid could travel in channel\n\t\t\t\t"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 col-md-4" }, [
      _c("div", { staticClass: "box relative" }, [
        _vm._v("Longitudinal Slope\n\t\t\t\t"),
        _c("img", { staticClass: "info", attrs: { src: "/icons/info.svg" } }),
        _vm._v(" "),
        _c("div", { staticClass: "info-text" }, [
          _c("div", [
            _c("img", { attrs: { src: "/images/slope.jpg", alt: "" } })
          ]),
          _vm._v(
            "\n\t\t\t\t\tLongitudinal ground slope % - Ground fall along channel drain \n\t\t\t\t"
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }