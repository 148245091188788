<template>
	<div class="contact-aco">
		<div class="header">
			<h2>Contact ACO</h2>
		</div>

		<div class="content">
			<p style="margin: 0 0 5px;">Select a region to {{ $t('message.enquire') }}</p>

			<select v-model="region" :class="{ placeholder: region == ''}" @change="$emit('update:region', $event.target.value)">
				<option disabled selected value="">Region</option>
				<option>Australia</option>
				<option>Canada</option>
				<option>Mexico</option>
				<option>New Zealand</option>
				<option>USA</option>
				<option>Vietnam</option>
				<option disabled value="">----------------</option>

				<option>Anguilla</option>
				<option>Antigua Barbuda</option>
				<option>Aruba</option>
				<option>Australia</option>
				<option>Barbados</option>
				<option>Belize</option>
				<option>Bermuda</option>
				<option>Brunei Darussalam</option>
				<option>Cambodia</option>
				<option>Canada</option>
				<option>Costa Rica</option>
				<option>Cuba</option>
				<option>Curacao</option>
				<option>Dominican Republic</option>
				<option>Fiji</option>
				<option>Grenada</option>
				<option>Guam</option>
				<option>Guatemala</option>
				<option>Haiti</option>
				<option>Honduras</option>
				<option>Indonesia</option>
				<option>Jamaica</option>
				<option>Kiribati</option>
				<option>Laos</option>
				<option>Malaysia</option>
				<option>Marshall Islands</option>
				<option>Mexico</option>
				<option>Micronesia</option>
				<option>Myanmar (Burma)</option>
				<option>Nauru</option>
				<option>New Zealand</option>
				<option>Nicaragua</option>
				<option>Palau</option>
				<option>Panama</option>
				<option>Papua New Guinea</option>
				<option>Philippines</option>
				<option>Puerto Rico</option>
				<option>Sint Maarten</option>
				<option>Samoa</option>
				<option>Singapore</option>
				<option>Solomon Islands</option>
				<option>St. Kitts - Nevis</option>
				<option>St. Lucia</option>
				<option>St. Vincent - Grenadines</option>
				<option>Thailand</option>
				<option>Tonga</option>
				<option>Tonga</option>
				<option>Trinidad and Tobago</option>
				<option>Turks & Caicos</option>
				<option>Tuvalu</option>
				<option>USA</option>
				<option>Vanuatu</option>
				<option>Vietnam</option>
				<option>Virgin Islands</option>
			</select>

			<div v-if="region">
				<div v-if="showUSA()" class="contact-info">
					<h3>USA</h3>
					<p><a class="no-link-style" href="tel:1 800-543-4764">1 800 543 4764</a></p>
					<p><a href="mailto:info@acousa.com">info@acousa.com</a></p>
					<p><a href="https://www.acousa.com/service/drain-design-form" target="_blank">Request hydraulic proposal</a></p>
				</div>
				<div v-else-if="showNewZealand()" class="contact-info">
					<h3>New Zealand</h3>
					<p><a class="no-link-style" href="tel:0800-448-080">0800 448 080</a></p>
					<p><a href="mailto:info@aconz.co.nz">info@aconz.co.nz</a></p>
					<p><a href="https://www.aconz.co.nz/technical/trench-drain-design-form" target="_blank">Request hydraulic proposal</a></p>
				</div>
				<div v-else-if="showCanada()" class="contact-info">
					<h3>Canada</h3>
					<p><a class="no-link-style" href="tel:877-226-4255">877 226 4255</a></p>
					<p><a href="mailto:info@acocan.ca">info@acocan.ca</a></p>
					<p><a href="https://www.acocan.ca/technical/trench-drain-design-request-form" target="_blank">Request hydraulic proposal</a></p>
				</div>
				<div v-else-if="showMexico()" class="contact-info">
					<h3>Mexico</h3>
					<p><a class="no-link-style" href="tel:(55)-5344-8547">(55) 5344 8547</a></p>
					<p><a href="mailto:info@aco.mx">info@aco.mx</a></p>
					<p><a href="https://www.aco.mx/servicio-tecnico/solicitud-de-diseno-de-trinchera" target="_blank">Request hydraulic proposal</a></p>
				</div>
				<div v-else-if="showAustralia()" class="contact-info">
					<h3>Australia</h3>
					<p><a class="no-link-style" href="tel:1300-765-226">1300 765 226</a></p>
					<p><a href="mailto:sales@acoaus.com.au">sales@acoaus.com.au</a></p>
					<p><a href="https://www.acodrain.com.au/product-support/request-design-assistance/" target="_blank">Request hydraulic proposal</a></p>
				</div>
				<div v-else-if="showVietnam()" class="contact-info">
					<h3>Vietnam</h3>
					<p><a class="no-link-style" href="tel:+84-90-364-4625">+84 90 364 4625</a></p>
					<p><a href="mailto:vuxuan.tran@aco.com">vuxuan.tran@aco.com</a></p>
					<p><a href="http://acovietnam.com/drain-design-form/" target="_blank">Request hydraulic proposal</a></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		region: String // prefill
	},
	// mounted() {
	// 	this.region = this.contactRegion;
	// },
	// data: function() {
	// 	return {
	// 		region: ''
	// 	}
	// },
	methods: {
		updateRegion(e) {
			this.$emit('update:region', null);
		},
		showUSA: function(){
			let countries = ['Antigua Barbuda', 'Barbados', 'Belize', 'Costa Rica', 'Cuba', 'Dominican Republic', 'Grenada', 'Guam', 'Guatemala', 'Haiti', 'Honduras', 'Jamaica', 'Nicaragua', 'Panama', 'Puerto Rico', 'St. Kitts - Nevis', 'St. Lucia', 'St. Vincent - Grenadines', 'Trinidad and Tobago', 'Anguilla', 'Aruba', 'Bermuda', 'Curacao', 'Sint Maarten', 'Turks & Caicos', 'Virgin Islands', 'USA'];
			return countries.includes(this.region);
		},
		showNewZealand: function(){
			let countries = ['New Zealand'];
			return countries.includes(this.region);
		},
		showCanada: function(){
			let countries = ['Canada'];
			return countries.includes(this.region);
		},
		showMexico: function(){
			let countries = ['Mexico'];
			return countries.includes(this.region);
		},
		showAustralia: function(){
			let countries = ['Brunei Darussalam', 'Tonga', 'Tuvalu', 'Vanuatu', 'Samoa', 'Singapore', 'Solomon Islands', 'Thailand', 'Papua New Guinea', 'Micronesia', 'Myanmar (Burma)', 'Nauru', 'Palau', 'Kiribati', 'Malaysia', 'Marshall Islands', 'Indonesia', 'Fiji', 'Tonga', 'Australia'];
			return countries.includes(this.region);
		},
		showVietnam: function(){
			let countries = ['Vietnam', 'Philippines', 'Laos', 'Cambodia'];
			return countries.includes(this.region);
		}
	}
}
</script>
