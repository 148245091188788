import { render, staticRenderFns } from "./registration.vue?vue&type=template&id=17facf46&"
import script from "./registration.vue?vue&type=script&lang=js&"
export * from "./registration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/sol1/hydrolite/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('17facf46')) {
      api.createRecord('17facf46', component.options)
    } else {
      api.reload('17facf46', component.options)
    }
    module.hot.accept("./registration.vue?vue&type=template&id=17facf46&", function () {
      api.rerender('17facf46', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/registration.vue"
export default component.exports