var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-5 col-md-3 result-row-img" }, [
      _c(
        "label",
        { staticClass: "result-row-select custom-checkbox no-print" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.run.selected,
                expression: "run.selected"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.run.selected)
                ? _vm._i(_vm.run.selected, null) > -1
                : _vm.run.selected
            },
            on: {
              change: function($event) {
                var $$a = _vm.run.selected,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(_vm.run, "selected", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.run,
                        "selected",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.run, "selected", $$c)
                }
              }
            }
          }),
          _vm._v(" "),
          _c("span"),
          _vm._v("\n\t\t\tSELECT\n\t\t")
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "img-wrap" }, [
        _c("img", {
          attrs: {
            width: "100%",
            src:
              "/images/results/" +
              _vm.run.Width +
              "mm " +
              _vm.run.Name +
              ".edit.png"
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-7 col-md-5" }, [
      _c("div", { staticClass: "box result-row-info" }, [
        _c("h2", [_vm._v(_vm._s(_vm.localisedName))]),
        _vm._v(" "),
        _vm.run.SlopedChannels.Valid
          ? _c("p", [
              _vm._v("Sloped (0.5% built-in fall) Channel Numbers:"),
              _c("br"),
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(_vm._s(_vm.run.SlopedChannels.String))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.run.ConstantChannels.Valid
          ? _c("p", [
              _vm._v("Constant Depth (Neutral) Channel Numbers:"),
              _c("br"),
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(_vm._s(_vm.run.ConstantChannels.String))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", [
          _vm._v("Invert Depth:"),
          _c("br"),
          _c("span", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v(_vm._s(_vm.invertDepthRange))
          ])
        ]),
        _vm._v(" "),
        _c("p", [
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: _vm.sampleFilename(_vm.run.Name)
              }
            },
            [_vm._v("Typical run (PDF)")]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "col-sm-offset-4 col-md-offset-0 col-md-2 utilisation-col"
      },
      [
        _vm.run.Utilisation >= 0
          ? [
              _c("utilisation", { attrs: { percentage: _vm.run.Utilisation } }),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.$t("message.Utilisation")))]),
              _vm._v(" "),
              _c("p", { staticClass: "subtle" }, [
                _vm._v("Capacity is defined as 100%")
              ])
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }