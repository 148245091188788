<template>
	<div class="water-run-off">
		<h2 class="step-heading"><span>Step 2:</span> Surface water run-off</h2>
		<p>Calculated uniform lateral flow.</p>

		<div class="row">
			<div class="col-xs-3 col-md-4">
				<div class="box relative">Lateral Intake
					<img class="info" src="/icons/info.svg" />
					<div class="info-text" style="width: 300px">
						Lateral Intake = Design Discharge / Length of Drain
					</div>
				</div>
			</div>
			<div class="col-xs-3 col-md-4">
				<div class="box">{{flowRate}} {{ flowUnitHuman() }}</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-3 col-md-4">
				<div class="box">Longitudinal Slope</div>
			</div>
			<div class="col-xs-3 col-md-4">
				<div class="box">{{slope}}%</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 col-md-offset-6 col-md-2">
				<div class="box">
					<button class="btn" style="width: 100%" @click="$emit('next', 3)">Next</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		slope: Number,
		flowRate: Number,
		flowRateUnit: String
	},
	methods:{
		flowUnitHuman: function() {
			return this.flowRateUnit == 'm' ? 'L/s/m' : 'GPM/ft';
		}
	}
}
</script>