<template>
	<div class="results">
		<h2 class="step-heading"><span>Step 4:</span> Register &amp; view results</h2>
		<h2 class="step-label">{{label}}</h2>
		<p>Summary of input data and calculations.</p>
		<div class="call-to-section">
			<div class="row">
				<div v-if="!dischargeManual" class="col-xs-12 col-sm-5 col-md-2">Drainage Area</div>
				<div v-if="!dischargeManual" class="col-xs-12 col-sm-5 col-md-2 result">{{area}} {{areaUnit === 'm' ? 'm²' : 'ft²'}}</div>

				<div class="col-xs-12 col-sm-5 col-md-2">Length of Drain to Outlet</div>
				<div class="col-xs-12 col-sm-5 col-md-2 result">{{lengthOfDrain()}}</div>

				<div class="col-xs-12 col-sm-5 col-md-2">Internal Channel Width</div>
				<div class="col-xs-12 col-sm-5 col-md-2 result">{{channelWidthToText(channelWidth)}}</div>

				<div v-if="!dischargeManual" class="col-xs-12 col-sm-5 col-md-2">Rainfall Intensity</div>
				<div v-if="!dischargeManual" :class="'col-xs-12 result ' + (dischargeManual ? 'col-sm-5 col-md-6' : 'col-sm-5 col-md-2')">{{rainfall}} {{rainfallUnit === 'm' ? 'mm/hr' : ' inch/hr'}}</div>

				<div class="col-xs-12 col-sm-5 col-md-2">Longitudinal Slope</div>
				<div :class="'col-xs-12 result ' + (dischargeManual ? 'col-sm-7 col-md-2' : 'col-sm-5 col-md-6')">{{slope}}%</div>

				<div class="col-xs-12 col-sm-5 col-md-2">Design Discharge</div>
				<div class="col-xs-12 col-sm-5 col-md-2 result">{{discharge}} {{dischargeUnitDisplay()}}</div>

				<div class="col-xs-12 col-sm-5 col-md-2">Lateral Intake</div>
				<div class="col-xs-12 col-sm-5 col-md-2 result">{{flowRate}} {{flowUnitHuman()}}</div>
			</div>
		</div>

		<!-- User has registered -->
		<div class="result-table page-break" v-if="registered">
			<div v-if="loading" class="loading">
				<span>Loading results...</span>
				<div></div>
			</div>
			<template v-else>
				<template v-if="results !== null && results.length > 0">
					<h2 class="step-heading">Results</h2>

					<div class="no-results" v-if="allResultsOverCapacity() && channelWidth < 300">
						<p>It seems that your catchment hydraulics have exceeded the capacity of the selected channel width. Try <a href="javascript:void(0)" @click="$emit('setStep', 3)">increasing the channel width</a>.</p>
					</div>

					<div class="no-results" v-if="allResultsOverCapacity() && channelWidth >= 300">
						<p>A {{channelWidth}}mm solution does not appear to be suitable for the length of drain selected. Consider shortening the length of drain (increase number of outlets) and reduce the corresponding drainage (catchment) area. <a href="javascript:void(0)" @click="$emit('setStep', 1)">Start again</a></p>
					</div>
					
					<div class="results-row" v-for="(result, index) in results" :key="result.ID">
						<results-row :invertDepthRange="invertDepthRange(result, lengthUnit)" :localisedName="localisedName(result)" :run="result" :countryGroup="projectCountryGroup()" />
					</div>
				</template>

				<!-- User has registered no results -->
				<div class="no-results" v-else>
					<p>We could not find a solution that meets your hydraulic requirements. Try varying your inputs.</p>
					<button class="btn" @click="$emit('setStep', 1)">Modify Values</button>
				</div>

				<div class="results-row results-notes">
					<h3 style="margin-bottom: 0">A note on specification</h3>
					<p>Based on your inputs, this is a hydraulic recommendation to guide you on the optimal channel 
						size and configuration. Select (tick) your preferred option above and hit the {{ $t('message.Enquire') }} button 
						below to request a full trench drain recommendation taking also into account loading, site 
						and user requirements. For more info on this calculation, <a href="/HYDROlite_standard_notes.pdf" target="_blank">click here</a>.</p>

					<div v-if="lengthMetric() >= 3 && lengthMetric() <= 20">
						<h3 style="margin-bottom: 0">A note on equivalent {{ $t('message.utilisation') }} values</h3>
						<p>Length of drain of {{ lengthLocalised(3) }} to {{ lengthLocalised(20) }} will produce equivalent {{ $t('message.utilisation') }} values 
						for the shallow and standard configurations. This is not an error! For these length of drains, 
						the design of the run is identical, refer to the 'Typical run (PDF)' link for the shallow 
						configuration and standard configuration.</p>
					</div>

					<div v-if="lengthMetric() > 39">
						<h3 style="margin-bottom: 0">A note on equivalent {{ $t('message.utilisation') }} values</h3>
						<p>A {{ lengthLocalised(40) }} length of drain will produce equivalent {{ $t('message.utilisation') }} values for the 
						standard and deep configurations. This is not an error! For this length of drain, 
						the design of the run is identical, refer to the 'Typical run (PDF)' link for the standard
						configuration and deep configuration.</p>
					</div>
				</div>

				<div class="cta-row no-print">
					<h2>NEXT STEPS</h2>
				</div>

				<div class="cta-row no-print">
					<div class="cta">
						<h4>I want to try another width</h4>
						<p>Need more capacity or want a more cost effective solution?</p>
						<img src="/images/sizes.gif" alt="">
						<div class="cta-links">
							<ul class="cta-list">
								<li><a class="btn btn-red btn-cta" @click="$emit('setStep', 3)">Try another width</a></li>
							</ul>
						</div>
					</div>
					<div class="cta">
						<h4>I want a record</h4>
						<p>Happy with the result?<br><br></p>
						<img src="/images/happy.jpg" alt="">
						<div class="cta-links">
							<ul class="cta-list">
								<li><a class="btn btn-red btn-cta" @click="sendResults()">Email me my result</a></li>
								<li><a class="btn btn-red btn-cta" @click="printResults()">Print this page</a></li>
							</ul>
						</div>
					</div>
					<div class="cta">
						<h4>What about the grate?</h4>
						<p>Choose a grate that works for my catchment hydraulics.</p>
						<img src="/images/gic-bg1.jpg" alt="">
						<div class="cta-links">
							<ul class="cta-list">
								<li><a class="btn btn-red btn-cta" target="_blank" :href="urlToPrefillGic()">Launch program</a></li>
							</ul>
						</div>
					</div>
				</div>

				<div class="cta-row no-print" style="padding-top: 40px; padding-bottom: 20px">
					<a href="/" class="btn btn-blue btn-enquire">Size another run</a>
				</div>

				<div v-if="enquiryComplete" class="thanks-row">
					Thank you for your interest! If you wish to make further enquiries <a href="#" @click="reset()">click here</a> or modify your values <a href="javascript:void(0)" @click="$emit('setStep', 1)">here</a>.
				</div>
				<div v-else class="enquire-row no-print">
					<button class="btn btn-red btn-enquire" @click="showEnquire = true">{{ $t('message.Enquire') }}</button>
					<div>Select (tick) channel size above and {{ $t('message.enquire') }} for more info (including blockage factors, pricing etc).</div>
				</div>
			</template>
		</div>
		<!-- User not registered, show form -->
		<div v-else>
			<p>Please complete the registration form to immediately reveal the calculation.</p>
			<div class="registration-wrapper">
				<div class="row center-md">
					<div class="col-xs-12 col-md-4">
						<div class="box">
							<registration ref="regoform" @setCountry="setCountry" @success="registered = true; getResults()"></registration>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="backdrop no-print" @click="showEnquire = false" v-if="showEnquire">
			<div class="modal" @click.stop>
				<div class="header">
					<h3>Channel {{ $t('message.Enquiry') }}</h3>
				</div>
				<div class="body">
					<enquire-form @success="enquiryComplete = true" @close="showEnquire = false" :results="selectedResults()" :units="lengthUnit" :submissionID="submissionID"/>
				</div>
			</div>
		</div>
		<div class="backdrop no-print" @click="showResultsSent = false" v-if="showResultsSent">
			<div class="modal" @click.stop>
				<div class="header">
					<h3>Results Sent</h3>
				</div>
				<div class="body">
					<div>
						<div style="text-align: center;">
							<p>We have sent a summary of your results to your email. Please feel free to contact ACO to discuss further.</p>
							<button class="btn" @click="showResultsSent = false">Close</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import Registration from "./registration.vue";
import EnquireForm from "./enquire-form.vue";
import ResultsRow from "./results-row.vue";
import * as Helper from '../helper.js';
import convert from 'convert-units';

export default {
	created: function() {
		this.registered = Boolean(this.getCookie("gp_user"));

		if (this.registered) {
			this.registeredPayload = Helper.getJWTPayload(this.getCookie("gp_user"));

			if (this.registeredPayload.projectCountry) {
				var ANZ = ['Australia', 'New Zealand', 'Brunei Darussalam', 'Cambodia', 'Tonga', 'Tuvalu', 'Vanuatu', 'Vietnam', 'Samoa', 'Singapore', 'Solomon Islands', 'Thailand', 'Papua New Guinea', 'Philippines', 'Micronesia', 'Myanmar (Burma)', 'Nauru', 'Palau', 'Kiribati', 'Malaysia', 'Marshall Islands', 'Indonesia', 'Fiji', 'Tonga'];
				if (ANZ.includes(this.registeredPayload.projectCountry)) {
					console.log("Setting en-AU locale for " + this.registeredPayload.projectCountry);
					this.$root.$i18n.locale = 'en-AU';
				} else {
					console.log("Setting en-US locale for " + this.registeredPayload.projectCountry);
					this.$root.$i18n.locale = 'en-US';
				}
			}
			this.getResults();
		}
	},
	props: {
		label: String,
		rainfall: Number,
		rainfallUnit: String,
		discharge: Number,
		dischargeUnit: String,
		dischargeManual: Number,
		area: Number,
		areaUnit: String,
		slope: Number,
		length: Number,
		lengthUnit: String,
		flowRateUnit: String,
		flowRate: Number,
		channelWidth: Number,
	},
	data: function() {
		return {
			registered: false,
			registeredPayload: {},
			showEnquire: false,
			showResultsSent: false,
			results: null,
			submissionID: null,
			enquiryComplete: false,
			loading: false,
			country: null
		}
	},
	methods: {
		invertDepthRange: function(run, units) {
			var startImperial = Helper.roundTo(convert(run.InvertDepthStart).from('mm').to('in'), 2) + '"';
			var endImperial = Helper.roundTo(convert(run.InvertDepthEnd).from('mm').to('in'), 2) + '"';
			
			if (units == "i") {
				return `${run.InvertDepthStart}mm (${startImperial}) to ${run.InvertDepthEnd}mm (${endImperial})`;
			} else {
				return `${run.InvertDepthStart}mm to ${run.InvertDepthEnd}mm`;	
			}
		},
		localisedName: function(run) {
			return this.channelWidthLocalised(run.Width) + " " + run.Name;
		},
		channelWidthLocalised: function(mmWidth) {
			if (this.areaUnit === 'm') {
				return mmWidth + "mm";
			} else {
				return Helper.roundTo(convert(mmWidth).from('mm').to('in'), 0) + '"';
			}
		},
		lengthLocalised: function(mLength) {
			if (this.lengthUnit === 'm') {
				return mLength + "m";
			} else {
				return Helper.roundTo(convert(mLength).from('m').to('ft'), 2) + ' ft ('+mLength+'m)';
			}
		},
		resultRowsForEmail: function() {
			var out = [];
			this.results.forEach(r => {
				out.push({
					Name: this.localisedName(r),
					Utilisation: String(Helper.roundTo(r.Utilisation, 0)),
					SlopedChannels: r.SlopedChannels.String,
					ConstantChannels: r.ConstantChannels.String,
					InvertDepthRange: this.invertDepthRange(r, this.lengthUnit)
				});
			});
			return out;
		},
		sendResults: function() {
			// Get payload from cookie again in case it was only set after created()
			this.registeredPayload = Helper.getJWTPayload(this.getCookie("gp_user"));

			// Email me my results
			this.$validator.validateAll().then((result) => {
				if (result) {
					var payload = {
						Label: this.label,
						RegisteredUserID: this.registeredPayload.UserID,
						ProjectCountry: this.registeredPayload.projectCountry,
						ProjectState: this.registeredPayload.ProjectState,
						Area: this.area,
						AreaUnit: this.areaUnit,
						Rainfall: this.rainfall,
						RainfallUnit: this.rainfallUnit,
						Length: this.length,
						LengthUnit: this.lengthUnit,
						FlowRate: this.flowRate,
						FlowRateUnit: this.flowRateUnit,
						ChannelWidth: this.channelWidth,
						Discharge: this.discharge,
						DischargeUnit: this.dischargeUnit,
						Slope: this.slope,
						Rows: this.resultRowsForEmail()
					};

					axios.post("/api/mailresults", payload).then((res) => {
						this.showResultsSent = true;
					}).catch((err) => {
						console.log(err);
					});
				}
			});
		},
		urlToPrefillGic() {
			return `https://gic.acotechsupport.com/?rainfall=${this.rainfall}&area=${this.area}&units=${this.areaUnit}&length=${this.length}&width=${this.channelWidth}`
		},
		setCountry(country) {
			this.country = country;
		},
		dischargeUnitDisplay() {
			return (this.dischargeUnit == "i") ? 'GPM' : 'L/s'
		},
		projectCountryGroup() {
			const c = this.registeredPayload.projectCountry ? this.registeredPayload.projectCountry : this.country; // from cookie or form
			const USA = ['Antigua Barbuda', 'Barbados', 'Belize', 'Costa Rica', 'Cuba', 'Dominican Republic', 'Grenada', 'Guam', 'Guatemala', 'Haiti', 'Honduras', 'Jamaica', 'Nicaragua', 'Panama', 'Puerto Rico', 'St. Kitts - Nevis', 'St. Lucia', 'St. Vincent - Grenadines', 'Trinidad and Tobago', 'Anguilla', 'Aruba', 'Bermuda', 'Curacao', 'Sint Maarten', 'Turks & Caicos', 'Virgin Islands', 'USA'];
			const ANZ = ['Australia', 'New Zealand', 'Brunei Darussalam', 'Cambodia', 'Tonga', 'Tuvalu', 'Vanuatu', 'Vietnam', 'Samoa', 'Singapore', 'Solomon Islands', 'Thailand', 'Papua New Guinea', 'Philippines', 'Micronesia', 'Myanmar (Burma)', 'Nauru', 'Palau', 'Kiribati', 'Malaysia', 'Marshall Islands', 'Indonesia', 'Fiji', 'Tonga'];
			const CA = ['Canada'];
			const MEX = ['Mexico'];
			if (USA.includes(c)) return 'USA';
			if (ANZ.includes(c)) return 'ANZ';
			if (CA.includes(c))  return 'CA';
			if (MEX.includes(c)) return 'MEX';
			return 'USA'; // default
		},
		getCookie: function(name) {
			let value = "; " + document.cookie;
			let parts = value.split("; " + name + "=");
			if (parts.length == 2) return parts.pop().split(";").shift();
		},
		reset: function() {
			//This gets called if a user to make further enquires
			//It's required we resubmit the input values so we get a new submission
			//This is due to the submission/enqury DB relationship
			this.showEnquire = false;
			this.results = null;
			this.submissionID = null;
			this.enquiryComplete = false;

			this.getResults();
		},
		allResultsOverCapacity: function() {
			for (let i = 0; i < this.results.length; i++) {
				if (this.results[i].Utilisation < 100) {
					return false;
				}
			}
			return true;
		},
		getResults: function() {
			this.loading = true;
			axios.post(
				"/api/submission",
				{
					Rainfall: this.rainfall,
					RainfallUnit: this.rainfallUnit,
					Area: this.area,
					AreaUnit: this.areaUnit,
					Length: this.length,
					LengthUnit: this.lengthUnit,
					LengthMetric: this.lengthMetric(),
					FlowRateUnit: this.flowRateUnit,
					FlowRate: this.flowRate,
					FlowRateMetric: this.flowRateMetric(),
					ChannelWidth: this.channelWidth,
					Discharge: this.discharge,
					DischargeUnit: this.dischargeUnit,
					Slope: this.slope
				}).then((res) => {
					this.loading = false;
					let results = res.data.Runs;
					
					if(results !== null){
						for(let i = 0; i < results.length; i++){
							results[i].selected = false;
						}
					}
					
					this.results = results;
					this.submissionID = res.data.SubmissionID;
				}).catch((err) => {
					this.loading = false;
					console.log(err);
				});
		},
		selectedResults: function(){
			//returns results that have been selected
			let selected = [];

			for(let result of this.results){
				if(result.selected) {
					selected.push(result);
				}
			}

			return selected;
		},
		channelWidthToText: function(width){
			switch (width) {
				case 0:
					return 'Slot';
				case 100:
					return '100mm (4")';
				case 200:
					return '200mm (8")';
				case 300:
					return '300mm (12")';
			}
		},
		getHighlightColor: function(percentage){
			switch (true) {
				case percentage < 75:
					return "green";
				case percentage < 90:
					return "#ff850e";
				case percentage < 100:
					return "#ff3b3b"
				default:
					return "#ff3b3b";
			}
		},
		flowUnitHuman: function() {
			return this.flowRateUnit == 'm' ? 'L/s/m' : 'GPM/ft';
		},
		flowRateMetric: function() {
			return this.flowRateUnit == 'm' ? this.flowRate : Helper.asLSM(this.flowRate)
		},
		lengthMetric: function() {
			return this.lengthUnit == 'm' ? this.length : Helper.roundTo(convert(this.length).from('ft').to('m'), 0)
		},
		printResults: function() {
			window.print()
		},
		lengthOfDrain: function() {
			if (this.lengthUnit == "m") {
				return `${this.length}m`;
			} else {
				var lm = Helper.roundTo(this.lengthMetric(), 0);
				return `${this.length} ft (${lm}m)`;
			}
		}
	},
	components: {
		'registration': Registration,
		'enquire-form': EnquireForm,
		'results-row': ResultsRow
	}
}
</script>
