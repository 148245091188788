var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calculator" }, [
    _c("div", { staticClass: "heading" }, [
      _c("div", { staticClass: "step-counter no-print" }, [
        _c("div", { staticClass: "steps" }, [
          _c(
            "div",
            {
              staticClass: "step",
              on: {
                click: function($event) {
                  return _vm.setStep(1)
                }
              }
            },
            [
              _c("label", [
                _vm._v("\n\t\t\t\t\t\tStep 1\n\t\t\t\t\t\t"),
                _c("div", {
                  class: [
                    "circle",
                    { active: _vm.step === 1, complete: _vm.stepCompletion[1] }
                  ]
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { class: ["line", { complete: _vm.stepCompletion[1] }] }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "step",
              on: {
                click: function($event) {
                  return _vm.setStep(2)
                }
              }
            },
            [
              _c("label", [
                _vm._v("\n\t\t\t\t\t\tStep 2\n\t\t\t\t\t\t"),
                _c("div", {
                  class: [
                    "circle",
                    { active: _vm.step === 2, complete: _vm.stepCompletion[2] }
                  ]
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { class: ["line", { complete: _vm.stepCompletion[2] }] }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "step",
              on: {
                click: function($event) {
                  return _vm.setStep(3)
                }
              }
            },
            [
              _c("label", [
                _vm._v("\n\t\t\t\t\t\tStep 3\n\t\t\t\t\t\t"),
                _c("div", {
                  class: [
                    "circle",
                    { active: _vm.step === 3, complete: _vm.stepCompletion[3] }
                  ]
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { class: ["line", { complete: _vm.stepCompletion[3] }] }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "step",
              on: {
                click: function($event) {
                  return _vm.setStep(4)
                }
              }
            },
            [
              _c("label", [
                _vm._v("\n\t\t\t\t\t\tStep 4\n\t\t\t\t\t\t"),
                _c("div", { class: ["circle", { active: _vm.step === 4 }] })
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "center subtle",
            staticStyle: { "padding-top": "5px" }
          },
          [_vm._v("You may click back to an earlier step if required")]
        )
      ])
    ]),
    _vm._v(" "),
    _vm.step !== 4
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12 col-md-8" }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _vm.step === 1
                  ? _c("catchment", {
                      attrs: {
                        label: _vm.label,
                        units: _vm.units,
                        rainfall: _vm.rainfall,
                        rainfallUnit: _vm.rainfallUnit,
                        discharge: _vm.discharge,
                        dischargeUnit: _vm.dischargeUnit,
                        dischargeManual: _vm.dischargeManual,
                        dischargeOverride: _vm.dischargeOverride,
                        area: _vm.area,
                        areaUnit: _vm.areaUnit,
                        slope: _vm.slope,
                        length: _vm.length,
                        lengthUnit: _vm.lengthUnit
                      },
                      on: {
                        next: _vm.setStep,
                        setAllUnits: _vm.setAllUnits,
                        "update:label": function($event) {
                          _vm.label = $event
                        },
                        "update:units": function($event) {
                          _vm.units = $event
                        },
                        "update:rainfall": function($event) {
                          _vm.rainfall = $event
                        },
                        "update:rainfallUnit": function($event) {
                          _vm.rainfallUnit = $event
                        },
                        "update:rainfall-unit": function($event) {
                          _vm.rainfallUnit = $event
                        },
                        "update:discharge": function($event) {
                          _vm.discharge = $event
                        },
                        "update:dischargeUnit": function($event) {
                          _vm.dischargeUnit = $event
                        },
                        "update:discharge-unit": function($event) {
                          _vm.dischargeUnit = $event
                        },
                        "update:dischargeManual": function($event) {
                          _vm.dischargeManual = $event
                        },
                        "update:discharge-manual": function($event) {
                          _vm.dischargeManual = $event
                        },
                        "update:area": function($event) {
                          _vm.area = $event
                        },
                        "update:areaUnit": function($event) {
                          _vm.areaUnit = $event
                        },
                        "update:area-unit": function($event) {
                          _vm.areaUnit = $event
                        },
                        "update:slope": function($event) {
                          _vm.slope = $event
                        },
                        "update:length": function($event) {
                          _vm.length = $event
                        },
                        "update:lengthUnit": function($event) {
                          _vm.lengthUnit = $event
                        },
                        "update:length-unit": function($event) {
                          _vm.lengthUnit = $event
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step === 2
                  ? _c("water-run-off", {
                      attrs: {
                        slope: _vm.slope,
                        flowRate: _vm.flowRate(),
                        flowRateUnit: _vm.flowRateUnit
                      },
                      on: {
                        next: _vm.setStep,
                        "update:slope": function($event) {
                          _vm.slope = $event
                        },
                        "update:flowRateUnit": function($event) {
                          _vm.flowRateUnit = $event
                        },
                        "update:flow-rate-unit": function($event) {
                          _vm.flowRateUnit = $event
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step === 3
                  ? _c("relevant-system", {
                      attrs: { channelWidth: _vm.channelWidth },
                      on: {
                        next: _vm.setStep,
                        "update:channelWidth": function($event) {
                          _vm.channelWidth = $event
                        },
                        "update:channel-width": function($event) {
                          _vm.channelWidth = $event
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-md-4" }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c("contact-aco", {
                  attrs: { region: _vm.region },
                  on: {
                    "update:region": function($event) {
                      _vm.region = $event
                    }
                  }
                })
              ],
              1
            )
          ])
        ])
      : _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12 col-md-12" }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c("results", {
                  attrs: {
                    label: _vm.label,
                    rainfall: _vm.rainfall,
                    rainfallUnit: _vm.rainfallUnit,
                    discharge: _vm.discharge,
                    dischargeUnit: _vm.dischargeUnit,
                    dischargeManual: _vm.dischargeManual,
                    area: _vm.area,
                    areaUnit: _vm.areaUnit,
                    slope: _vm.slope,
                    length: _vm.length,
                    lengthUnit: _vm.lengthUnit,
                    flowRateUnit: _vm.flowRateUnit,
                    flowRate: _vm.flowRate(),
                    channelWidth: _vm.channelWidth
                  },
                  on: { setStep: _vm.setStep }
                })
              ],
              1
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }