<template>
	<div class="row">
		<div class="col-sm-5 col-md-3 result-row-img">
			<label class="result-row-select custom-checkbox no-print">
				<input type="checkbox" v-model="run.selected"/>
				<span></span>
				SELECT
			</label>
			<div class="img-wrap">
				<img width="100%" :src="`/images/results/${run.Width}mm ${run.Name}.edit.png`" />
			</div>
		</div>
		<div class="col-sm-7 col-md-5">
			<div class="box result-row-info">
				<h2>{{localisedName}}</h2>

				<p v-if="run.SlopedChannels.Valid">Sloped (0.5% built-in fall) Channel Numbers:<br><span style="font-weight: bold">{{run.SlopedChannels.String}}</span></p>
				<p v-if="run.ConstantChannels.Valid">Constant Depth (Neutral) Channel Numbers:<br><span style="font-weight: bold">{{run.ConstantChannels.String}}</span></p>
				<p>Invert Depth:<br><span style="font-weight: bold">{{invertDepthRange}}</span></p>

				<p><a target="_blank" :href="sampleFilename(run.Name)">Typical run (PDF)</a></p>
			</div>
		</div>
		<div class="col-sm-offset-4 col-md-offset-0 col-md-2 utilisation-col">
			<template v-if="run.Utilisation >= 0">
				<utilisation :percentage="run.Utilisation"></utilisation>
				<p>{{ $t('message.Utilisation') }}</p>
				<p class="subtle">Capacity is defined as 100%</p>
			</template>
		</div>
	</div>
</template>

<script>
import Utilisation from "./utilisation.vue";
import convert from 'convert-units';
import * as Helper from '../helper.js';

export default {
	props: {
		run: Object,
		countryGroup: String,
		localisedName: String,
		invertDepthRange: String
	},
	data: function(){
		return {
			showEnlargedImg: false,
		}
	},
	methods: {
		sampleFilename(channelName) {
			var out;
			if (channelName.includes('shallow')) {
				out = 'Shallow Slope';
			}
			if (channelName.includes('standard')) {
				out = 'Standard Slope';
			}
			if (channelName.includes('deep')) {
				out = 'Deep Slope';
			}
			return ['/run-pdf/', out, ' - ', this.countryGroup, '.pdf'].join('');
		}
	},
	components: {
		'utilisation': Utilisation
	}
}
</script>