<template>
	<div class="calculator">
		<div class="heading">
			<div class="step-counter no-print">
				<div class="steps">
					<div class="step" @click="setStep(1)">
						<label>
							Step 1
							<div :class="['circle', {active: step === 1, complete: stepCompletion[1]}]"></div>
						</label>
					</div>
					<div :class="['line', {complete: stepCompletion[1]}]"></div>
					<div class="step" @click="setStep(2)">
						<label>
							Step 2
							<div :class="['circle', {active: step === 2, complete: stepCompletion[2]}]"></div>
						</label>
					</div>
					<div :class="['line', {complete: stepCompletion[2]}]"></div>
					<div class="step" @click="setStep(3)">
						<label>
							Step 3
							<div :class="['circle', {active: step === 3, complete: stepCompletion[3]}]"></div>
						</label>
					</div>
					<div :class="['line', {complete: stepCompletion[3]}]"></div>
					<div class="step" @click="setStep(4)">
						<label>
							Step 4
							<div :class="['circle', {active: step === 4}]"></div>
						</label>
					</div>
				</div>
				<p class="center subtle" style="padding-top: 5px;">You may click back to an earlier step if required</p>
			</div>
		</div>

		<div class="row" v-if="step !== 4">
			<div class="col-xs-12 col-md-8">
				<div class="box">
					<catchment
						v-if="step === 1"
						@next="setStep"
						@setAllUnits="setAllUnits"
						:label.sync="label"
						:units.sync="units"
						:rainfall.sync="rainfall"
						:rainfallUnit.sync="rainfallUnit"
						:discharge.sync="discharge"
						:dischargeUnit.sync="dischargeUnit"
						:dischargeManual.sync="dischargeManual"
						:dischargeOverride="dischargeOverride"
						:area.sync="area"
						:areaUnit.sync="areaUnit"
						:slope.sync="slope"
						:length.sync="length"
						:lengthUnit.sync="lengthUnit" />
					<water-run-off
						v-if="step === 2"
						@next="setStep" 
						:slope.sync="slope"
						:flowRate="flowRate()"
						:flowRateUnit.sync="flowRateUnit" />
					<relevant-system
						v-if="step === 3"
						@next="setStep"
						:channelWidth.sync="channelWidth" />
				</div>
			</div>
			<div class="col-xs-12 col-md-4">
				<div class="box">
					<contact-aco :region.sync="region" />
				</div>
			</div>
		</div>
		<div class="row" v-else>
			<div class="col-xs-12 col-md-12">
				<div class="box">
					<results
						:label="label"
						:rainfall="rainfall"
						:rainfallUnit="rainfallUnit"
						:discharge="discharge"
						:dischargeUnit="dischargeUnit"
						:dischargeManual="dischargeManual"
						:area="area"
						:areaUnit="areaUnit"
						:slope="slope"
						:length="length"
						:lengthUnit="lengthUnit"
						:flowRateUnit="flowRateUnit"
						:flowRate="flowRate()"
						:channelWidth="channelWidth"
						@setStep="setStep" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Catchment from './catchment-hydraulics.vue'
import WaterRunOff from './water-run-off.vue'
import ContactACO from './contact-aco.vue';
import RelevantSystem from './relevant-system.vue';
import Results from './results.vue';
import * as Helper from '../helper.js';

export default {
	props: {
		prefill: Object
	},
	mounted() {
		// Prefill some values based on those provided via the Vue instantiation in main.js
		this.rainfall = this.prefill.rainfall;
		this.rainfallUnit = this.areaUnit = this.lengthUnit = this.dischargeUnit = this.flowRateUnit = this.units = this.prefill.units; // (m)etric or (i)mperial
		this.area = this.prefill.area;
		this.length = this.prefill.length;
		this.slope = Helper.roundTo(this.prefill.slope, 2);
		this.channelWidth = this.prefill.width;
	},
	data: function() {
		return {
			step: 1,
			stepCompletion: {
				1: false,
				2: false,
				3: false
			},
			//Field bindings:
			label: '',
			units: '',
			rainfall: null,
			rainfallUnit: '',
			dischargeManual: null,
			dischargeOverride: false,
			dischargeUnit: '',
			area: null,
			areaUnit: '',
			slope: null,
			length: null,
			lengthUnit: '',
			flowRateUnit: '',
			channelWidth: null,
			region: ''
		}
	},
	computed: {
		discharge: {
			get: function() {
				if (this.dischargeOverride) {
					return this.dischargeManual;
				} else {
					return this.calcDischarge();
				}
			},
			set: function(val) {
				this.dischargeOverride = true;
				this.dischargeManual = val;
			}
		}
	},
	methods: {
		calcDischarge() {
			/**
			 * For metric the equation is
			 * (Drainage Area (m2)  x  Rainfall Intensity (mm/hr) ) / 3600
			 * 
			 * For imperial the equation is
			 * (Drainage Area (ft2)  x  Rainfall Intensity (in/hr) ) / 96.23
			 */
			var div = this.units == "i" ? 96.23 : 3600;
			return Helper.roundTo((this.area * this.rainfall) / div, 2);
		},
		setStep: function(step) {
			switch(this.step) {
				case 1:
					this.stepCompletion[1] = (((this.area && this.areaUnit && this.rainfall && this.rainfallUnit) || (this.discharge && this.dischargeUnit)) && this.length && this.lengthUnit);
					break;
				case 2:
					this.stepCompletion[2] = Boolean(this.flowRateUnit);
					break;
				case 3:
					this.stepCompletion[3] = (this.channelWidth !== null);
					break;
			}
			this.step = step;
		},
		flowRate: function() {
			let flow = Helper.calculateFlow(
				this.discharge,
				this.length,
				this.units == "i" ? 3 : 5
			);

			return flow;
		},
		setAllUnits: function(unit) {
			this.rainfallUnit = unit;
			this.areaUnit = unit;
			this.lengthUnit = unit;
			this.flowRateUnit = unit;
			this.dischargeUnit = unit;
		}
	},
	components: {
		'catchment': Catchment,
		'water-run-off': WaterRunOff,
		'contact-aco': ContactACO,
		'relevant-system': RelevantSystem,
		'results': Results
	}
}
</script>