var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relevent-system" }, [
    _vm._m(0),
    _vm._v(" "),
    !_vm.preselected
      ? _c("p", [_vm._v("Choose a trench drain width.")])
      : _c("p", [
          _vm._v(
            "The fields below have been pre-filled based on the preselected grate."
          )
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-md-6" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            class: { placeholder: _vm.channelWidth == null },
            attrs: { name: "channelWidth", disabled: _vm.preselected },
            domProps: { value: _vm.channelWidth },
            on: {
              change: function($event) {
                _vm.$emit(
                  "update:channelWidth",
                  $event.target.value == "" ? null : Number($event.target.value)
                )
              }
            }
          },
          [
            _c("option", { attrs: { disabled: "", selected: "", value: "" } }, [
              _vm._v("Select")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "100" } }, [_vm._v('100mm (4")')]),
            _vm._v(" "),
            _c("option", { attrs: { value: "200" } }, [_vm._v('200mm (8")')]),
            _vm._v(" "),
            _c("option", { attrs: { value: "300" } }, [_vm._v('300mm (12")')])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("channelWidth"),
                expression: "errors.has('channelWidth')"
              }
            ],
            staticClass: "vee-err"
          },
          [_vm._v(_vm._s(_vm.errors.first("channelWidth")))]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticStyle: { "margin-right": "20px" } }, [
        _c("img", {
          class: _vm.channelWidth == 100 ? "selected-border" : "",
          attrs: { src: "/images/100.gif", alt: "100mm" },
          on: {
            click: function($event) {
              return _vm.$emit("update:channelWidth", 100)
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { "margin-right": "20px" } }, [
        _c("img", {
          class: _vm.channelWidth == 200 ? "selected-border" : "",
          attrs: { src: "/images/200.gif", alt: "200mm" },
          on: {
            click: function($event) {
              return _vm.$emit("update:channelWidth", 200)
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", [
        _c("img", {
          class: _vm.channelWidth == 300 ? "selected-border" : "",
          attrs: { src: "/images/300.gif", alt: "300mm" },
          on: {
            click: function($event) {
              return _vm.$emit("update:channelWidth", 300)
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12 col-md-offset-8 col-md-2" }, [
        _c("div", { staticClass: "box" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              staticStyle: { width: "100%" },
              on: {
                click: function($event) {
                  return _vm.nextPage()
                }
              }
            },
            [_vm._v("Next")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "step-heading" }, [
      _c("span", [_vm._v("Step 3:")]),
      _vm._v(" Identifying relevant system")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 col-md-4" }, [
      _c("div", { staticClass: "box relative" }, [
        _vm._v("Internal Channel Width \n\t\t\t\t"),
        _c("img", { staticClass: "info", attrs: { src: "/icons/info.svg" } }),
        _vm._v(" "),
        _c("div", { staticClass: "info-text" }, [
          _vm._v(
            "\n\t\t\t\t\tHydraulic capacities increase with channel widths\n\t\t\t\t"
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }